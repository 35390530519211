<template>
  <div>
    <v-card flat dense>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h5 class="font-weight-light">REQUEST INFORMATION</h5></v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-3">
          <v-row dense>
            <v-col md="12" cols="12" v-if="is_ftv === 1">
              <v-select
                v-model="ftv_from_bank_id"
                :items="from_bank_items"
                item-value="id"
                item-text="bank_code"
                label="From Bank"
                required
                :rules="rules.combobox_rule"
                dense
              ></v-select>
            </v-col>
            <v-col md="12" cols="12" v-if="is_ftv === 1">
              <v-select
                v-model="ftv_to_bank_id"
                :items="to_bank_items"
                item-value="id"
                item-text="bank_code"
                label="To Bank"
                required
                :rules="rules.combobox_rule"
                dense
              ></v-select>
            </v-col>
            <v-col md="12" cols="12" v-if="is_ftv != 1">
              <v-select
                v-if="position != 'EMPLOYEE'"
                v-model="branch_id"
                :items="branch_items"
                item-value="id"
                item-text="branch_code"
                label="Branch"
                required
                :rules="rules.combobox_rule"
                dense
                @change="selected_particulars"
              ></v-select>
              <v-text-field
                v-else
                class="mx-2"
                v-model="this.employee_branch_data.branch_code"
                dense
                label="Branch"
                readonly
              ></v-text-field>
            </v-col>
            <v-col md="12" cols="12" v-if="is_ftv != 1">
              <v-select
                v-model="particulars_id"
                :items="particulars_items"
                item-value="id"
                item-text="particulars"
                label="Particulars"
                required
                :rules="rules.combobox_rule"
                dense
                @change="selected_particulars"
              ></v-select>
            </v-col>
            <v-col md="12" cols="12" v-if="has_description">
              <customize-components
                :key="this.key"
                :description="this.description"
                :category_id="this.category_id"
                :particulars_id="this.particulars_id"
                :particulars_items="this.particulars_items"
                :branch_id="this.branch_id"
                :particulars_description_items="this.particulars_description_items"
                v-on:on_is_ca="on_is_ca"
                v-on:on_close_description_dialog_have_amount="on_close_description_dialog_have_amount"
                v-on:on_maf_no="on_maf_no"
                v-on:on_close_description_dialog="on_close_description_dialog"

                :is_regular="this.is_regular"
                :is_pdc="this.is_pdc"
                :is_ftv="this.is_ftv"
              ></customize-components>
            </v-col>
            <v-col md="12" cols="12" v-else>
              <v-text-field
                v-model="description"
                label="Description"
                dense
                :rules="rules.default_max_255_character_and_no_empty_rule"
                v-if="is_ftv != 1"
              ></v-text-field>
              <v-text-field
                v-model="description"
                label="Description"
                dense
                :rules="rules.default_max_255_character_and_no_empty_rule"
                disabled
                v-else
              ></v-text-field>
            </v-col>
            <v-col md="12" cols="12" v-if="is_regular != 1">
              <v-text-field
                v-model="cheque_date"
                label="Cheque Date"
                dense
                type="date"
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col md="12" cols="12">
              <v-select
                v-model="search_by"
                :items="is_ftv != 1?['Employee', 'Affiliate', 'Others']:['Affiliate']"
                label="Search By"
                required
                :rules="rules.combobox_rule"
                dense
                @change="reseter_search()"
              ></v-select>
            </v-col>
            <v-col md="12" cols="12" v-if="search_by === 'Employee' || search_by === 'Affiliate'">
              <v-autocomplete
                v-model="search"
                :items="search_items"
                :loading="isLoading"
                @keyup.enter="searching($event.target.value)"
                hide-no-data
                item-text="name"
                item-value="id"
                label="Search"
                placeholder="Press Enter to Search "
                :prepend-icon="icons.mdiAccountSearchOutline"
                @change="get_search_items_info"
              ></v-autocomplete>
            </v-col>
            <v-col md="12" cols="12" v-if="search_by === 'Others'">
              <v-text-field
                v-model="payment_for"
                label="Payment For"
                dense
                :rules="rules.default_max_255_character_and_no_empty_rule"
                readonly
                @click="credit_information_click('Payment For')"
              ></v-text-field>
            </v-col>

            <v-col md="12" cols="12" v-if="search_by === 'Others'">
              <v-text-field
                v-model="credit_to"
                label="Credit To"
                dense
                :rules="rules.default_max_255_character_and_no_empty_rule"
                readonly
                @click="credit_information_click('Credit To')"
              ></v-text-field>
            </v-col>

            <v-col md="12" cols="12" v-if="search_by != 'Others'">
              <v-text-field
                v-model="payment_for"
                label="Payment For"
                dense
                :rules="rules.default_max_255_character_and_no_empty_rule"
                disabled
              ></v-text-field>
            </v-col>

            <v-col md="12" cols="12" v-if="search_by != 'Others'">
              <v-text-field
                v-model="credit_to"
                label="Credit To"
                dense
                :rules="rules.default_max_255_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col md="12" cols="12">
              <v-text-field
                v-model="amount"
                label="Amount"
                dense
                type="number"
                :rules="rules.default_max_45_character_and_no_empty_rule"
                v-if="!disable_amount"
              ></v-text-field>
              <v-text-field
                v-model="amount"
                label="Amount"
                dense
                type="number"
                :rules="rules.default_max_45_character_and_no_empty_rule"
                readonly
                v-else
              ></v-text-field>
            </v-col>
            <!-- alert -->
            <v-col cols="12" v-show="alert_da">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message_da }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>
            <v-col cols="12" md="12" v-if="is_from_other === 1">
              <v-btn class="w-full" color="primary" @click="save_request_data(0)"
                     v-if="!saving_request_data">
                Update changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
            <v-col cols="12" md="8" v-if="is_from_other === 0">
              <v-btn class="w-full" color="primary" @click="save_request_data(1)"
                     v-if="!saving_request_data">
                Save changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
            <v-col cols="12" md="4" v-if="is_from_other === 0">
              <v-btn class="w-full" color="success" @click="done_request"
                     v-if="!saving_request_data">
                Done Request
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
    <v-dialog v-model="is_information_credit" persistent max-width="80%">
      <CreditInformation v-on:data="on_credit_information_click"
                         :key="this.key"
                         :information_details="this.information_details"></CreditInformation>
      <v-btn color="error" @click="is_information_credit = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>
<script>
  import {mapActions, mapGetters} from 'vuex'
  import {mdiAlertOutline, mdiAccountSearchOutline} from '@mdi/js'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import customizeComponents from './CustomizeRequest'
  import CreditInformation from './CreditInformation'

  export default {
    components: {
      customizeComponents,
      snackBarDialog,
      CreditInformation,
    },
    props: {
      category_id: Number,
      request_id: Number,
      is_regular: Number,
      is_pdc: Number,
      is_ftv: Number,
      branch_items: Array,
      particulars_items: Array,
      from_bank_items: Array,
      to_bank_items: Array,
      is_from_other: Number,
      from_others: Object,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiAccountSearchOutline,
        },
        can_change_branch: false,
        saving_request_data: false,
        information_details: '',

        disable_amount: false,
        is_information_credit: false,

        alert_da: false,
        alert_message_da: '',

        key: 0,
        credit_to: '',
        amount: '',
        payment_for: '',
        search_by: '',
        cheque_date: '',
        description: '',
        particulars_id: '',
        branch_id: 0,

        title_incentives: '',

        isLoading: false,

        ftv_from_bank_id: null,
        ftv_to_bank_id: null,
        maf_no: null,

        search: '',
        search_member: '',
        search_items: [],

        description_strict: false,

        has_description: false,
        ca_employee_id: null,
        is_ca: false,

        particulars_description_items: [],
      }
    },
    created() {
      if (this.position === 'EMPLOYEE') {
        this.branch_id = this.employee_branch_id
      }
      if (this.is_ftv === 1) {
        this.description = 'FUND TRANSFER'
        this.particulars_id = 'Fund Transfer Only'
      }
      if (this.is_from_other === 1) {
        this.branch_id = this.from_others.branch_id === null ? 0 : this.from_others.branch_id
        this.particulars_id = this.from_others.particulars_id === null ? 0 : this.from_others.particulars_id
        this.payment_for = this.from_others.payment_for
        this.credit_to = this.from_others.credit_to
        this.amount = this.from_others.amount
        this.ca_employee_id = this.from_others.ca_employee_id
        this.cheque_date = this.from_others.cheque_date === null ? '' : this.from_others.cheque_date
        this.is_ca = this.from_others.is_ca === 1
        this.ftv_from_bank_id = this.from_others.ftv_from_bank_id
        this.ftv_to_bank_id = this.from_others.ftv_to_bank_id
        this.search_by = 'Others'
        this.selected_particulars()
        this.description = this.from_others.description
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', [
        'employee_id',
        'month_of',
        'address',
        'contact',
        'name',
        'department',
        'position',
        'employee_category_id',
        'employee_branch_data',
        'employee_branch_id',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('employee', ['search_all_active_employee']),
      ...mapActions('request', ['done_request_form']),
      ...mapActions('affiliatee', ['search_all_active_affiliate']),
      ...mapActions('request_data', ['register_request_data']),
      selected_particulars() {
        this.key++
        this.has_description = false
        this.description = ''
        if (this.particulars_items.length > 0) {
          var index = this.particulars_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.particulars_id)
          if (index != -1) {
            this.has_description = this.particulars_items[index].has_description === 1
            this.particulars_description_items = this.particulars_items[index].data
            if (this.has_description) {
              if (this.is_from_other === 1) {
                if (this.particulars_id === this.from_others.particulars_id) {
                  this.description = this.from_others.description
                } else {
                  this.description = 'Please Click Me!!!'
                }
              } else {
                this.description = 'Please Click Me!!!'
              }
            }
          }
        }
      },
      on_close_description_dialog(value) {
        this.description = value
      },
      on_maf_no(value) {
        this.maf_no = value
      },
      on_close_description_dialog_have_amount(value) {
        this.disable_amount = true
        this.amount = value + ''
      },
      on_is_ca(value) {
        this.is_ca = value
      },
      credit_information_click(value) {
        this.key++
        this.information_details = value
        this.is_information_credit = true
      },
      on_credit_information_click(value, same, details) {
        this.is_information_credit = false
        if (details === 'Payment For') {
          this.payment_for = value
          if (same) {
            this.credit_to = value
          }
        }
        if (details === 'Credit To') {
          this.credit_to = value
          if (same) {
            this.payment_for = value
          }
        }
      },
      reseter_search() {
        this.search_member = ''
        this.search_items = []
        this.credit_to = ''
        this.payment_for = ''
        if (this.search_by === 'Others') {
          this.credit_to = 'PLEASE CLICK ME..'
          this.payment_for = 'PLEASE CLICK ME..'
        }
      },
      searching(value) {
        this.credit_to = ''
        this.payment_for = ''
        this.isLoading = true
        this.search_items = []

        if (this.search_by === 'Employee') {
          this.search_all_active_employee({
            search_word: value,
          }).then(response => {
            this.search_items = response.data
            this.search = ''
            this.isLoading = false
          })
        } else if (this.search_by === 'Affiliate') {
          this.search_all_active_affiliate({
            search_word: value,
            particulars_id: this.is_ftv === 1 ? 'Fund Transfer Only' : this.particulars_id,
            branch_id: this.is_ftv === 1 ? '' : this.branch_id,
          }).then(response => {
            this.isLoading = false
            this.search_items = response.data
            this.search = ''
          })
        }
      },
      get_search_items_info(value) {
        if (this.search_items.length > 0) {
          var index = this.search_items
            .map(function (x) {
              return x.id
            })
            .indexOf(value)
          if (index != -1) {
            this.payment_for = this.search_items[index].name
            this.credit_to = this.search_items[index].name
            this.ca_employee_id = this.search_items[index].id
            if (this.search_by === 'Affiliate') {
              if (this.search_items[index].amount > 0 && this.description.includes('Existing')) {
                this.disable_amount = true
                this.amount = this.search_items[index].amount
              } else {
                if (this.amount<=0){
                  this.disable_amount = false
                }
              }
            }
          } else {
            this.search = ''
            this.credit_to = ''
            this.payment_for = ''
            this.search_items = []
          }
        } else {
          this.search_items = []
          this.credit_to = ''
          this.search = ''
          this.payment_for = ''
        }
      },
      done_request() {
        this.saving_request_data = true
        this.alert_da = false
        const data = new FormData()
        data.append('request_id', this.request_id)
        this.done_request_form(data)
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.saving_request_data = false
            this.$emit('data', true)
          })
          .catch(error => {
            this.alert_da = true
            this.alert_message_da = error
            this.saving_request_data = false
          })
      },
      save_request_data(value) {
        this.saving_request_data = true
        this.alert_da = false
        if (this.$refs.form.validate()) {
          if (this.description === 'Please Click Me!!!') {
            this.alert_da = true
            this.alert_message_da = 'Please Fill Up Description First'
            this.saving_request_data = false
          } else if (
            this.ftv_from_bank_id > 0 &&
            this.ftv_to_bank_id > 0 &&
            this.ftv_from_bank_id === this.ftv_to_bank_id
          ) {
            this.alert_da = true
            this.alert_message_da = 'Please Select Different To Bank Code '
            this.saving_request_data = false
          } else {
            const data = new FormData()
            data.append('category_id', this.category_id)
            data.append('request_id', this.request_id)
            data.append('branch_id', this.branch_id === 0 ? '' : this.branch_id)
            data.append('particulars_id', this.particulars_id === 0 ? '' : this.particulars_id)
            data.append('ftv_from_bank_id', this.ftv_from_bank_id)
            data.append('ftv_to_bank_id', this.ftv_to_bank_id)
            data.append('description', this.description)
            data.append('payment_for', this.payment_for)
            data.append('amount', this.amount)
            data.append('credit_to', this.credit_to)
            data.append('cheque_bank_id', null)
            data.append('cheque_no', 0)
            data.append('cheque_date', this.cheque_date)
            data.append('pdc_status', parseInt(this.is_pdc) + parseInt(this.is_ftv))
            data.append('ca_status', 0)
            data.append('auto_debit_voucher_no', 0)
            data.append('is_for_not_equal_check', 0)
            data.append('for_not_equal_check_amount', 0)
            data.append('maf_no', this.maf_no)
            data.append('is_damayan_service_paid', 0)
            data.append('is_released_claims', 0)
            data.append('is_pending_cheque_booklet', 0)
            data.append('is_liquidated', 0)
            data.append('is_ftv_deposit_done', parseInt(this.is_ftv))
            data.append('is_ftv_customize', 0)
            data.append('is_ca', this.is_ca)
            data.append('ca_employee_id', this.ca_employee_id)
            data.append('description_strict', this.description_strict)
            data.append('is_new', value)
            data.append('id', this.is_from_other ? this.from_others.id : null)
            this.register_request_data(data)
              .then(response => {
                var color = 'success'
                if (response.status === 201) {
                  color = 'error'
                }
                this.change_snackbar({
                  show: true,
                  color: color,
                  text: response.data,
                })
                this.saving_request_data = false
                this.disable_amount = false
                this.description_strict = false
                this.$emit('data', true)
                this.selected_particulars()
              })
              .catch(error => {
                this.alert_da = true
                this.alert_message_da = error
                this.saving_request_data = false
              })
          }
        } else {
          this.alert_da = true
          this.alert_message_da = 'Please Fill up the field/s'
          this.saving_request_data = false
        }
      },
    },
  }
</script>
